export const jobs = [
  {
    id: 1,
    title: "Junior Recruiter",
    jobType: "Full-Time Job, 100% Remote Work, (Work from Home)",

    skills:
      "Computer skill, English Communication, Human Resource Management, jobposting site, Office Management, Recruitment Procedure, Working under pressure,",

    compensation: "The salary is negotiable.",

    location: "Anywhere in Bangladesh (100% Remote)",

    keySellingPoints: `
    Steadfast Communications is looking for some youthful motivated, and energetic people who will be
    responsible for hiring staff in the USA`,
    jobContext: `
    Steadfast Communications is a sister concern of "Steadfast International Services, LLC"
    We are looking for a "Junior Recruiter"
    Full-Time Job, 100% Remote Work, (Work from Home)
    Educational Level: Minimum Bachelor's Degree from any Reputed Organization. (Human resource
    Management Background candidate will get priority)
    Belongings: Laptop/Desktop, High-Speed Internet (These will not be provided by the organization)
    Office Hours: Monday-Friday (06:00 pm - 03:00 am / 7:00 pm - 4:00 am)
    Weekend: Saturday & Sunday
    Language: English (Conversational)
    Steadfast Communications a sister concern of Steadfast International Services, LLC is looking for a
    Junior Recruiter, who will be Youthful, Motivated, energetic, efficient in English speaking & most
    importantly passionate about recruiting. Fresh graduates who are looking for some experience in Human Resource Field to build their Careers.`,

    jobResponsibilities: `Work on Live job projects & Source candidates from Linkedin, Indeed, and Monster.
    Source Candidates by Cold Calling.
    Candidate's Resume Collecting, Screening & Short-listing.
    Maintain Database for particular assigned projects.
    Doing E-mail Campaigns to find candidates for different projects.
    Establish engagement with the candidates in every possible way.
    Overall understand the industry & Follow the industry practices`,

    additionalRequirements: `
    Age 23 to 32 years
    Candidate should have sound knowledge of Corporate Culture.
    Candidate should have English-speaking skills.
    Interested to work in USA Based time Schedule (06:00 pm - 03:00 am/ 7:00 pm-4:00 am)`,

    compensationAndBenefits: `
    Performance bonus, Weekly 2 holidays, Gratuity, Over time allowance
    Salary Review: Half Yearly
    Festival Bonus: 2 (Yearly)
    Attendance Bonus Per Month
    Other's Leave as per Labor Law.
    `,
  },
  {
    id: 2,
    title: "Principal Digital Marketing Manager",
    jobType: "Full-Time Job, 100% Remote Work, (Work from Home)",

    skills: `Data Analysis, Excellent sales and negotiation skills, Networking and
      Communications Skills, Project Management Skills, Recruiting Sales, Sales & Marketing, Smart presentation Skill`,

    compensation: "The salary is negotiable.",

    location: "Anywhere in Bangladesh (100% Remote)",

    keySellingPoints: `
    Social Platform marketing in USA MARKET EXPERIENCED is a Must.
    English Fluent Presentational for USA Clients
    Decent, Collaborative & Charming Person
    Hunting Business Mentality
    Passion & Love to talk
    Cold Calling & Email Marketing`,

    jobContext: `
    Steadfast Communications is the sister concern of Steadfast International Services, LLC. Which is
    the leading talent acquisition platform that delivers top candidates to enterprises by leveraging the
    largest community of recruiters and sourcing technology platforms. Companies receive ready-to-
    hire candidates quickly and efficiently while streamlining their recruiting efforts. Steadfast
    International Services is tech-enabled solutions that include Direct Hire, Contingent Hire, as well as
    Agency Management. Learn more about: www.Steadfastints.com
    This is a great opportunity for an ambitious individual with experience in sales in the staffing and
    recruiting industry. We are looking for someone who is energetic, organized, ambitious, and ready
    for a big challenge
    This is a leadership role of the Company and shall take part in the strategic Sales & Marketing
    matters. The main role of this position is to drive growth and innovation to ensure a leading position
    in recruiting Industry. Nurturing new businesses and growing aggressively in new profitable
    segments.
    This position will report directly to the Managing Director & Country Director`,

    jobResponsibilities: `Research and identify new business opportunities in the USA- including new markets, growth
    areas, trends, customers, partnerships, products, and services - or new ways of reaching existing
    markets. Business development can be applied to almost all types of business, but you may choose
    to specialize in a certain sector in the USA, such as:
    Cloud Market & Cloud Product Setup Industry in the USA.
    Contacting potential clients to establish rapport and arrange meetings.
    Planning and overseeing new marketing initiatives in the USA.
    Find clients using several platforms and social networks like LinkedIn, Facebook, Twitter, and
    Instagram.
    5-7 plus years of experience in the USA technology industry.
    Researching organizations and individuals to find new opportunities.
    Increasing the value of current customers while attracting new ones.
    cold call sales, email marketing, and data analysis.
    Finding and developing new markets and improving sales.
    Attending conferences, meetings, and industry events.
    Developing quotes and proposals for clients.
    Developing goals for the development team and business growth and ensuring they are met.
    Types of business management: Finance, IT, Insurance, Healthcare, Manufacturing, Construction,
    Telecommunication

    Experience Requirements:
    5 to 7 year(s)
    The applicants should have experience in the following area(s):
    Business Development, Cold Calling, Corporate Marketing, Email Marketing, Recruiting
    Industry, Sales & Marketing, Social Media Marketing, Technology Industry, USA market
    The applicants should have experience in the following business area(s):
    IT Enabled Service, Manpower Recruitment`,

    additionalRequirements: `
    Age 30 to 40 years
    5-7 years of sales experience within the Recruiting and Staffing industry in the USA or Sales and
    Marketing experience in the Technology industry.
    Canada/UK Market experience will also get priority.
    Entrepreneurial mindset with a true sales hunter approach.
    Strong established relationships among key influencers within the Recruiting and Staffing Industry.
    Strong communication and interpersonal skills.
    Business management in technology and digital marketing.
    Tech-savvy, proficient in using tools such as Zoom, Dynamics CRM, and MS Office Suite (Excel,
    PowerPoint, Outlook)
    Proven working experience as a business development manager, sales executive, or a relevant role
    Proven sales track record. selling recruiting solutions or services to large enterprises.`,

    compensationAndBenefits: `
    Mobile bill, Performance bonus, Weekly 2 holidays
    Salary Review: Half Yearly
    Festival Bonus: 2 (Yearly)
    `,
  },
  {
    id: 3,
    title: "Data Analyst",
    jobType: "Full-Time Job, 100% Remote Work, (Work from Home)",

    skills: `Proven data entry work experience, as a Data Analyst or Business Analyst, Experience with MS Office and data programs, Familiarity with administrative duties, Typing speed and accuracy, Excellent knowledge of correct spelling, grammar, and punctuation, Attention to detail, Confidentiality, Organization skills, with an ability to stay focused on assigned tasks`,

    compensation: "The salary is negotiable.",

    location: "Anywhere in Bangladesh (100% Remote)",

    keySellingPoints: `
    Collecting and entering data in databases.
    Maintaining accurate records of valuable company information.
    Data entry skills fast typing.`,

    jobContext: `
    Responsibilities include collecting and entering data in databases and maintaining accurate records of valuable company information. Our ideal candidate has essential data entry skills, like fast typing an eye for detail, and familiarity with spreadsheets and online forms. You will have to work with a Recruiter team.  

    Previous experience as a Data Analyst or similar position will be considered an advantage. Ultimately, a successful Data Analyst will be responsible for maintaining accurate, up-to-date, and useable information in our systems.`,

    jobResponsibilities: `Insert customer and account data by inputting text-based and numerical information from source documents within time limits 

    Compile, verify accuracy, and sort information according to priorities to prepare source data for computer entry 
    
    Review data for deficiencies or errors, correct any incompatibilities if possible, and check the output 
    
    Research and obtain further information for incomplete documents 
    
    Apply data program techniques and procedures 
    
    Generate reports, store completed work in designated locations, and perform backup operations 
    
    Keep information confidential 
    
    Respond to queries for information and access relevant files 
    
    Comply with data integrity and security policies 
    
    Requirements and skills 

    Proven data entry work experience, as a Data Analyst or Business Analyst, 

    Experience with MS Office and data programs 

    Familiarity with administrative duties 

    Typing speed and accuracy 

    Excellent knowledge of correct spelling, grammar, and punctuation 

    Attention to detail 

    Confidentiality 

    Organization skills, with an ability to stay focused on assigned tasks 

  

    Must-Have a Certificate which indicates experience as Data Analyst or Business Analyst. 

    Interested to work in the USA Based time Schedule (06:00 pm - 03:00 am/ 7:00 pm-4:00 am) 

    Full-Time Job, 100% Remote Work, (Work from Home) 

    Educational Level: Minimum Bachelor's Degree from any Reputed Organization. 

    Belongings: Laptop/Desktop, High-Speed Internet (The organization will not provide these) 

    Weekend: Saturday & Sunday 

    Language: English (Conversational) `,

    additionalRequirements: `
    Age 30 to 40 years
    5-7 years of sales experience within the Recruiting and Staffing industry in the USA or Sales and
    Marketing experience in the Technology industry.
    Canada/UK Market experience will also get priority.
    Entrepreneurial mindset with a true sales hunter approach.
    Strong established relationships among key influencers within the Recruiting and Staffing Industry.
    Strong communication and interpersonal skills.
    Business management in technology and digital marketing.
    Tech-savvy, proficient in using tools such as Zoom, Dynamics CRM, and MS Office Suite (Excel,
    PowerPoint, Outlook)
    Proven working experience as a business development manager, sales executive, or a relevant role
    Proven sales track record. selling recruiting solutions or services to large enterprises.`,

    compensationAndBenefits: `
    Performance bonus, Weekly 2 holidays, Gratuity, Overtime allowance 

    Salary Review: Half Yearly 
    
    Festival Bonus: 2 (Yearly) 
    
    Attendance Bonus Per Month 
    
    Other's Leave as per Labor Law 
    
     
    `,
  },
];

export const employers = [
  {
    id: 1,
    picture: "nawreen.jpg",
    name: "Rifat Nawreen",
    narration:
      "Rifat Nawreen, Senior Recruiter and one of the Cheerful Team Leader who also hunting fit candidates for Recruiting Team of Steadfast International Services, LLC. She is Versatile, Loyal & Dedicated to Organizational growth.",
  },
  {
    id: 2,
    picture: "shakhawat.jpg",
    name: "Shakhawat Hossain",
    narration:
      "Shakhawat Hossain, Assistant HR Manager of Steadfast International Services, LLC. His strongest asset is his multitasking ability. He is conduct interviews & hunts fit candidates for Recruiting Team.",
  },
  {
    id: 3,
    picture: "brishty.jpg",
    name: "Tasnima Rahman Bristy",
    narration:
      "Tasnima Rahman Bristy worked here as an executive recruiter. She believe one can easily achieve success by being patient, sincere and believing in self. Maintaining proper determination and friendly environment is the key here.",
  },
  {
    id: 4,
    picture: "hamidul.jpg",
    name: "MD. Hamidul Hoque",
    narration:
      "Md. Hamidul Hoque is Most patinate , hard working Executive Recruiter who supports candidates to get their desire job and providing a great service through out the process.",
  },

  {
    id: 5,
    picture: "ridi.jpg",
    name: "Mahtareen Haque Ridi",
    narration:
      "Mahtareen Haque Ridi, is an Executive Recruiter at Steadfast International Services, LLC who is determined to get the job done correctly and efficiently. She is a multi-skilled individual who has goals, morals and knows how to follow guide lines and rules.",
  },
  {
    id: 6,
    picture: "mostafa.png",
    name: "S.M. Mostafa Jamal",
    narration:
      "S.M. Mostafa Jamal a Technical Recruiter as well as Computer Science Student fond of web development/software engineering, he has a unique perspective on both sides of the hiring process. In his role as a technical recruiter, he gained a deep understanding of what companies look for in a software engineers, as well as the challenges they face in finding and hiring top talent. As a technical recruiter, he use his experience to find and hiring the best technical talent for many organization. He is able to speak their language and understand the technical requirements of the job, making it easier for him to tailor his application and stand out from other candidates.",
  },
  {
    id: 7,
    picture: "amin.jpg",
    name: "Abdullah AL Amin",
    narration: `This is Amin working as an Executive Recruiter being well organized, truthful and passionate. A pioneer is an individual who knows the path, goes the path and shows the path.`,
  },
  {
    id: 8,
    picture: "nishat.jpg",
    name: "Nishat Anjum",
    narration: `Nishat Anjum is delighted to be a part of Steadfast International Services, where she can use her skills and talents to demonstrate her expertise and boost the company's growth.`,
  },
  {
    id: 9,
    picture: "tahmid.jpg",
    name: "Tahmid Ahmed",
    narration:
      "Tahmid Ahmed is a recruiter responsible for finding and attracting talented individuals to fill job openings within the organization they represent. They create job descriptions and postings, screen resumes and applications, conduct interviews, and act as a point of contact for candidates throughout the recruitment process.",
  },
  {
    id: 9,
    picture: "zubair.png",
    name: "Sheikh Sauban Zubair ",
    narration:
      "Sheikh Sauban Zubair currently operates as a management trainee officer at Steadfast. He looks to source candidates from different backgrounds using his expertise in different fields as well as his vast knowledge of different cultures. As one of the youngest members at Steadfast, he is always striving to learn and improve on his previous work.",
  },

  {
    id: 10,
    picture: "shubrata.jpg",
    name: "Shubrata Das",
    narration:
      "Shubrata Das is an optimistic person and a learning enthusiast who is deeply passionate for making amazing contributions in ensuring ceaseless personal and professional growth for himself. He's strongly spirited for ensuring brilliant footsteps in his career and highly committed to work with integrity, diligence and agility for the tasks, duties and responsibilities given to him within a job.",
  },

  {
    id: 11,
    picture: "adhora.jpg",
    name: "Adhora Zaman",
    narration:
      "Adhora Zaman is a highly motivated and energetic recruitment Professional. She is an effective communicator and negotiator maintaining strong working relationships with clients, leadership teams, and her peers. She possesses excellent time management skills as well as the ability to handle multiple tasks simultaneously and operate effectively within strict deadlines and challenging situations.",
  },
  {
    id: 12,
    picture: "mahim.jpg",
    name: "Mahmudur Rahman",
    narration:
      "This is Mahmudur Rahman born and raised in the Chattogram. I have done my post Graduation from Port City International University majoring in HRM now working in Steadfast International Service, LLC. as a Management Trainee Officer. I help Job seekers to find their desired job.",
  },
  {
    id: 13,
    picture: "jahid.jpg",
    name: "Khandokar Jahidul",
    narration:
      "Khandokar Jahidul, Holds the management trainee officer position at Steadfast Internationals Services LLC. Passionate about headhunting. Sourcing and attracting candidates by using databases and different platforms. Prepare recruitment materials and post jobs for the appropriate jobs.",
  },
];
